<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-row
        class="p-2"
        align-v="center"
        align-h="between"
      >
        <b-col
          class="font-weight-bold d-flex align-items-center"
          cols="auto"
        >
          <span class="text-uppercase">productos y servicios ofertados</span>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm px-2 mr-1"
            @click="creationModalVisible = !creationModalVisible"
          >
            Nuevo producto / servicio
          </b-button>
        </b-col>
      </b-row>
      <hr class="m-0">
      <template
        v-for="offerLines in groupedOfferLines"
      >
        <OfferLinesTable
          :key="offerLines.id"
          :offer-lines="offerLines"
        />
        <hr
          :key="offerLines.id + 'separator'"
          class="m-0"
        >
      </template>
      <b-row
        align-v="center"
        class="p-2"
      >
        <b-col
          class="text-dark-gray font-weight-bold"
          cols="auto"
        >
          <span class="text-uppercase">totales oferta</span>
        </b-col>
      </b-row>
      <b-row
        align-h="around"
        align-v="center"
        no-gutters
        class="py-2 px-1 bg-action-blue"
      >
        <b-col cols="auto">
          <span class="text-dark-gray font-very-small font-weight-bold">VENTA TOTAL: </span>
          <span class="text-gray-50">{{ offer? offer.total_sale : 0 | numberToLocalString }} €</span>
        </b-col>
        <b-col cols="auto">
          <span class="text-dark-gray font-very-small font-weight-bold">COSTE TOTAL: </span>
          <span class="text-gray-50">{{ offer ? offer.total_cost : 0 | numberToLocalString }} €</span>
        </b-col>
        <b-col cols="auto">
          <span class="text-dark-gray font-very-small font-weight-bold">BENEFICIOS: </span>
          <span class="text-gray-50"> {{ offer ? offer.benefit : 0 | numberToLocalString }} €</span>
        </b-col>
        <b-col cols="auto">
          <span class="text-dark-gray font-very-small font-weight-bold">MARGEN: </span>
          <span class="text-gray-50">{{ offer? offer.margin : 0 | numberToLocalString }} %</span>
        </b-col>
      </b-row>
    </b-overlay>
    <OfferLineCreationModal
      v-model="creationModalVisible"
      :offer="offer"
      @offer-line-created="handleOfferLineCreated"
    />
  </b-card>
</template>

<script>
import OfferLinesTable from '@/components/offers/table/OfferLinesTable.vue'
import OfferLineCreationModal from '@/components/offers/modal/OfferLineCreationModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferLinesCard',
  components: { OfferLineCreationModal, OfferLinesTable },
  data() {
    return {
      loading: false,
      creationModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('offer', {
      offer: 'getOffer',
      groupedOfferLines: 'getGroupedOfferLines',
    }),
  },
  methods: {
    handleOfferLineCreated() {
      this.creationModalVisible = false
    },
  },
}
</script>

<style scoped>
</style>
