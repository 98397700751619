<template>
  <b-modal
    v-model="model"
    size="lg"
    hide-footer
    centered
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <h1 class="font-large-1 text-center pt-2">
        Selecciona la categoría
      </h1>
      <validation-observer ref="creationOfferLineModalForm">
        <b-form
          class="py-2"
          @submit.prevent
        >
          <b-row align-h="center">
            <b-col
              sm="12"
              lg="10"
            >
              <validation-provider
                v-slot="validationContext"
                name="Categoría"
                rules="required"
              >
                <b-form-group
                  label="Categoría"
                  label-for="offer-category"
                >
                  <CategoryGroupSelect v-model="categoryGroup" />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleAcceptButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import CategoryGroupSelect from '@/components/category/select/CategoryGroupSelect.vue'
import { mapActions } from 'vuex'
import OfferLinesApi from '@/api/offer-lines-api'

export default {
  name: 'OfferLineCreationModal',
  components: { CategoryGroupSelect },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    offer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      categoryGroup: null,
      loading: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
  },
  methods: {
    ...mapActions('offer', ['loadOffer']),
    async handleAcceptButtonClick() {
      const valid = await this.$refs.creationOfferLineModalForm.validate()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        await OfferLinesApi.create({ offer_id: this.offer?.id, category_group_id: this.categoryGroup.id })
        await this.loadOffer(this.offer?.id)
        this.$emit('offer-line-created')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
::v-deep .modal-content {
  height: 330px;
}
</style>
