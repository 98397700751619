<template>
  <div>
    <b-row
      align-h="between"
      align-v="center"
      class="p-2"
    >
      <b-col
        class="text-gray-50 font-weight-bold font-small"
        cols="auto"
      >
        <span class="text-uppercase">{{ name }}</span>
      </b-col>
      <b-col cols="auto">
        <b-link @click="collapse = !collapse">
          <FeatherIcon
            v-if="!collapse"
            icon="ChevronDownIcon"
          />
          <FeatherIcon
            v-else
            icon="ChevronUpIcon"
          />
        </b-link>
      </b-col>
    </b-row>
    <b-collapse v-model="collapse">
      <b-overlay
        :show="loading"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
      >
        <b-table
          hover
          responsive
          :items="lines"
          :fields="columns"
          :no-local-sorting="true"
        >
          <template #cell(units)="data">
            <b-form-input
              v-model="data.item.units"
              placeholder="Uds."
              type="number"
              @change="updateOfferLine(data.item)"
            />
          </template>

          <template #cell(product_id)="data">
            <ProductSelect
              v-model="data.item.product"
              :category-group-id="data.item.category_group_id"
              sale-products-visible
              class="mb-1"
              @option-selected="handleProductOptionSelected($event, data.item)"
              @clear="handleProductOptionCleared(data.item)"
            />
            <b-form-input
              v-model="data.item.description"
              placeholder="Descripción"
              :disabled="!!offer.rates.length"
              @change="updateOfferLine(data.item)"
            />
          </template>

          <template #cell(unit_cost)="data">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="data.item.unit_cost"
                placeholder="Coste unidad"
                type="number"
                @change="updateOfferLine(data.item)"
              />
              <b-input-group-append is-text>
                €
              </b-input-group-append>
            </b-input-group>
          </template>

          <template #cell(total_cost)="data">
            {{ data.item.unit_cost * data.item.units | numberToLocalString }} €
          </template>

          <template #cell(margin)="data">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="data.item.margin"
                placeholder="Margen"
                type="number"
                :formatter="marginFormatter"
                @change="handleMarginInputChange($event, data.item)"
              />
              <b-input-group-append is-text>
                <feather-icon icon="PercentIcon" />
              </b-input-group-append>
            </b-input-group>
          </template>

          <template #cell(unit_sale)="data">
            <validation-provider
              v-slot="validationContext"
              name="Venta unidad"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="data.item.unit_sale"
                  placeholder="Venta unidad"
                  type="number"
                  @change="handleUnitSaleInputChange($event, data.item)"
                />
                <b-input-group-append is-text>
                  €
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>
          </template>

          <template #cell(total_sale)="data">
            {{ data.item.total_sale | numberToLocalString }} €
          </template>
          <template #cell(actions)="data">
            <span
              class="d-inline-block text-danger"
              @click="handleDeleteIconClick(data.item.id)"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </span>
          </template>
        </b-table>
        <b-row
          align-h="around"
          align-v="center"
          no-gutters
          class="p-1 bg-action-blue"
        >
          <b-col cols="auto">
            <span class="text-dark-gray font-very-small font-weight-bold">VENTA TOTAL: </span>
            <span class="text-gray-50">{{ offerLines.total_sale | numberToLocalString }} €</span>
          </b-col>
          <b-col cols="auto">
            <span class="text-dark-gray font-very-small font-weight-bold">COSTE TOTAL: </span>
            <span class="text-gray-50">{{ offerLines.total_cost | numberToLocalString }} €</span>
          </b-col>
          <b-col cols="auto">
            <span class="text-dark-gray font-very-small font-weight-bold">BENEFICIOS: </span>
            <span class="text-gray-50"> {{ offerLines.benefit | numberToLocalString }} €</span>
          </b-col>
          <b-col cols="auto">
            <span class="text-dark-gray font-very-small font-weight-bold">MARGEN: </span>
            <b-input-group class="margin-input-width input-group-merge d-inline-flex">
              <b-form-input
                v-model="offerLines.margin"
                placeholder="Margen"
                type="number"
                :formatter="marginFormatter"
                @change="handleTotalMarginChange"
              />
              <b-input-group-append is-text>
                <feather-icon icon="PercentIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-overlay>
    </b-collapse>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapActions, mapGetters } from 'vuex'
import OfferLinesApi from '@/api/offer-lines-api'
import ProductSelect from '@/components/products/select/ProductSelect.vue'

export default {
  name: 'OfferLinesTable',
  components: { ProductSelect },
  props: {
    offerLines: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      collapse: false,
      loading: false,
      columns: [
        {
          label: 'UNIDAD',
          key: 'units',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '110px' },
        },
        {
          label: 'PRODUCTO/SERVICIO',
          key: 'product_id',
          sortable: false,
        },
        {
          label: 'COSTE UNIDAD',
          key: 'unit_cost',
          sortable: false,
          thStyle: { 'min-width': '125px' },
        },
        {
          label: 'COSTE TOTAL',
          key: 'total_cost',
          sortable: false,
          thStyle: { 'min-width': '125px' },
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: 'MARGEN',
          key: 'margin',
          sortable: false,
          thStyle: { 'min-width': '125px' },
        },
        {
          label: 'VENTA UNIDAD',
          key: 'unit_sale',
          sortable: false,
          thStyle: { 'min-width': '125px' },
        },
        {
          label: 'VENTA TOTAL',
          key: 'total_sale',
          sortable: false,
          thStyle: { 'min-width': '125px' },
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '75px' },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('offer', { offer: 'getOffer' }),
    name() {
      if (!this.offerLines) {
        return 'Sin nombre'
      }

      return this.offerLines.name || 'Sin nombre'
    },
    lines() {
      if (!this.offerLines) {
        return []
      }

      return this.offerLines.lines
    },
  },
  methods: {
    ...mapActions('offer', ['loadOffer']),
    async updateOfferLine(offerLine) {
      this.loading = true
      try {
        await OfferLinesApi.edit(offerLine.id, offerLine)
        await this.loadOffer(this.offer.id)
      } finally {
        this.loading = false
      }
    },
    async handleDeleteIconClick(offerLineId) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: 'Se borrará la linea',
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await OfferLinesApi.delete(offerLineId)
        await this.loadOffer(this.offer?.id)
      } finally {
        this.loading = false
      }
    },
    handleUnitSaleInputChange(unitSale, line) {
      const offerLine = { ...line, type: 'unit_sale' }
      delete offerLine.margin
      this.updateOfferLine(offerLine)
    },
    handleMarginInputChange(margin, line) {
      const offerLine = { ...line, type: 'margin' }
      delete offerLine.unit_sale
      this.updateOfferLine(offerLine)
    },
    handleProductOptionSelected(product, line) {
      const offerLine = { ...line }
      offerLine.product_id = product.id

      this.updateOfferLine(offerLine)
    },
    handleProductOptionCleared(line) {
      const offerLine = { ...line }
      offerLine.product_id = ''

      this.updateOfferLine(offerLine)
    },
    async handleTotalMarginChange(margin) {
      this.loading = true
      try {
        await OfferLinesApi.updateMarginLineGroup(this.offer?.id, this.offerLines.id, margin)
        await this.loadOffer(this.offer?.id)
      } finally {
        this.loading = false
      }
    },
    marginFormatter(numberValue) {
      const parts = numberValue.split('.')
      if (parts.length === 0 || parts[0] === '') {
        return numberValue
      }

      const integerPart = parseInt(parts[0], 10)
      if (integerPart >= 100) {
        return '99.99'
      }

      if (parts.length === 1) {
        return `${integerPart}`
      }

      const decimalPart = parts[1].substring(0, 10)
      return `${integerPart}.${decimalPart}`
    },
  },
}
</script>

<style scoped>
.margin-input-width {
  width: 150px;
}
</style>
