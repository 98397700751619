import Vue from 'vue'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_OFFER_LINES } from '@/shared/constants/resources'

class OfferLinesApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }

  updateMarginLineGroup(offer_id, category_group_id, margin) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(
          `${this.resource}/update-margin-line-group`,
          { offer_id, category_group_id, margin },
        )
        Vue.$toast('Margen recalculado con éxito.')
        resolve(response.data)
      } catch (error) {
        console.error(error)
        Vue.$toast.error('Ocurrió un error al recalcular el margen. Por favor inténtelo de nuevo mas tarde.')
        reject(error)
      }
    })
  }
}

export default new OfferLinesApi(RESOURCE_OFFER_LINES)
